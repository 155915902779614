<template>
  <v-container>
    <DxToolbar class="select-group">
      <DxItem
        #default
        location="before"
        locate-in-menu="never"
      >
        <div
          class="toolbar-label"
          style="font-weight:bold;color:cyan;"
        >
          주간 구매품 소요량
        </div>
      </DxItem>
      <DxItem
        #default
        location="after"
        locate-in-menu="never"
      >
        <dx-check-box
          class="column-filtering"
          :value="isExpaneded"
          text="전항목 확장"
          @value-changed="onExpandedAll"
        />
      </DxItem>
    </DxToolbar>
    <DxPivotGrid
      ref="summarySubPivot"
      :allow-sorting-by-summary="true"
      :allow-sorting="true"
      :allow-filtering="true"
      :allow-expand-all="true"
      :show-borders="true"
      :show-column-grand-totals="false"
      :show-row-grand-totals="false"
      :show-row-totals="true"
      :show-column-totals="false"
      :data-source="dataSource"
      :row-header-layout="rowHeaderLayout"
      :height="pivotHeight"
      @cell-prepared="onCellPrepared"
      @cell-click="onCellClick"
    >
      <DxFieldChooser
        :enabled="true"
        :height="400"
      />
    </DxPivotGrid>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </v-container>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import axios from 'axios'
import { DxPivotGrid, DxFieldChooser } from 'devextreme-vue/pivot-grid'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'
import { DxCheckBox } from 'devextreme-vue'

export default {
  name: 'MainPage',
  components: {
    Loading,
    DxToolbar,
    DxItem,
    DxPivotGrid,
    DxFieldChooser,
    DxCheckBox
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      dataSource: null,
      pivotDataSource: null,
      rowHeaderLayout: 'tree',
      pivotHeight: null,
      wipStockList: null,
      isExpaneded: true
    }
  },
  computed: {
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.refrshMrp()
  },
  mounted () {
    this.pivotHeight = (this.$Q('html').height() - 90) - 64
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  updated () {
    this.$refs.summarySubPivot.instance.getDataSource().expandAll('Region')
  },
  methods: {
    handleResize () {
      this.pivotHeight = (this.$Q('html').height() - 90) - 64
    },
    onCellClick (e) {
      if (e.area === 'column' && e.columnIndex === 0 && e.rowIndex === 0) { e.cancel = true }
    },
    onCellPrepared (e) {
      let split = null
      let calcWip = false
      let where = null
      if (e.area === 'column' && e.columnIndex === 0 && e.rowIndex === 1) {
        this.$Q(e.cellElement).text('재공')
      }
      if (e.area === 'data' && e.columnIndex === 0) {
        calcWip = false
        if (this.isExpaneded) {
          if (e.rowIndex % 2 === 0) {
            this.$Q(e.cellElement).text('0')
            calcWip = true
          } else {
            this.$Q(e.cellElement).text('')
          }
        } else {
          this.$Q(e.cellElement).text('0')
          calcWip = true
        }
        if (calcWip) {
          split = e.cell.rowPath[0].split('(')
          this.$Q(e.cellElement).css('color', 'red')
          where = this.$_.findWhere(this.wipStockList, { 품번: split[0] })
          this.$Q(e.cellElement).text(where.현재공)
        }
      }
    },
    onExpandedAll (e) {
      this.isExpaneded = e.value
      if (e.value) {
        this.$refs.summarySubPivot.instance.getDataSource().expandAll('Region')
      } else {
        this.$refs.summarySubPivot.instance.getDataSource().collapseAll('Region')
      }
    },
    refrshMrp () {
      const param = {
        fromDate: AppLib.getToday(),
        toDate: AppLib.calcDateFromToday(1),
        goodsType: ConstDef.완제품
      }
      const lineMrp = this.$_sp.runNoEncodeFindProc('spFindAllWeekProductLineMrpByDate', param)
      const wipStock = this.$_sp.runNoEncodeFindProc('spFindAllWipStockByToDateGByProductFromText', { toDate: AppLib.calcDateFromToday(1) })
      axios.all([lineMrp, wipStock])
        .then(axios.spread((...reponse) => {
          this.isLoading = false
          this.pivotDataSource = this.$_sp.MakeModel(reponse[0])
          this.wipStockList = this.$_sp.MakeModel(reponse[1])
          this.setInitPivotDataSource()
        }))
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    setInitPivotDataSource () {
      this.dataSource = new PivotGridDataSource({
        fields: [
          {
            caption: 'Region',
            dataField: '품번명',
            area: 'row'
          },
          {
            caption: '라인명',
            dataField: '라인명',
            area: 'row',
            width: '150'
          },
          {
            dataField: '소요일자',
            dataType: 'date',
            area: 'column',
            groupInterval: 'month',
            expanded: true
          },
          {
            dataField: '소요일자',
            dataType: 'date',
            area: 'column',
            groupInterval: 'day'
          },
          {
            caption: '소요량',
            dataField: '소요량',
            dataType: 'number',
            summaryType: 'sum',
            format: '#,##0',
            area: 'data'
          }

        ],
        store: this.pivotDataSource
      })
      console.log(this.dataSource)
    }
  }
}
</script>

<style lang="scss">
  .data-grid {
    margin-top:0px;
  }
</style>
