<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container class="pt-0">
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <v-text-field
            id="input-pcode1"
            v-model="qrPcodeKey"
            autocomplete="off"
            label="품번"
            placeholder="QR 스캔"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-text-field
            v-if="receiptInfo.품명"
            v-model="receiptInfo.품명"
            label="품명"
            :readonly="true"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="5"
        >
          <v-text-field
            id="input-qty"
            v-model="takeQty"
            autocomplete="off"
            label="출고수량"
            type="number"
            clearable
          />
        </v-col>
        <v-col
          v-if="getOption"
          cols="7"
        >
          <v-autocomplete
            id="select-line"
            v-model="selectedLine"
            :items="lineLookup"
            label="출고생산라인"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="onClickSave"
          >
            {{ getBtnTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="qrKey"
            label="일련번호"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.현재수량"
            label="현재수량"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="receiptInfo.장소위치코드"
        dense
      >
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.장소위치코드"
            label="위치코드"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.위치명"
            label="위치명"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.발주번호"
            label="발주번호"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.거래처명"
            label="거래처명"
            :readonly="true"
            filled
            autocomplete="off"
          />
        </v-col>
      </v-row>
    </v-container>
    <loading
      :active.sync="isLoading"
      color="rgb(2, 110, 156)"
      loader="bars"
    />
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    QrCodeScanComponent
  },
  props: {
    inoutOption: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      qrPcodeKey: null,
      qrKey: ' ',
      qrLocation: null,
      receiptInfo: { 품명: null, 현재수량: ' ', 발주번호: ' ', 거래처명: ' ' },
      locationInfo: null,
      takeQty: null,
      selectedLine: null,
      lineLookup: [],
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  computed: {
    getOption () {
      return this.inoutOption === ConstDef.자재출고
    },
    getBtnTitle () {
      return this.inoutOption === ConstDef.자재출고 ? '자재출고' : '불량품출고'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    this.refreshLineInfo()
  },
  mounted () {
    // this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.qrPcodeKey = null
      this.takeQty = null
      this.receiptInfo = { 품명: null, 현재수량: ' ', 발주번호: ' ', 거래처명: ' ' }
      setTimeout(() => {
        this.$Q('#input-pcode1').focus()
      }, 100)
    },
    onClickSave (e) {
      if (this.qrPcodeKey === null || this.qrPcodeKey.length === 0) {
        this.$snotify.error('품목 정보를 스캔하여 주세요')
        this.initInput()
        return
      }
      if (this.takeQty === null || this.takeQty.length === 0) {
        this.$snotify.error('출고 수량을 입력하여 주세요')
        this.$Q('#input-qty').focus()
        return
      }
      if (parseInt(this.takeQty) > parseInt(this.receiptInfo.현재수량)) {
        this.$snotify.error('출고 수량은 현재수량 보다 클수 없습니다.')
        this.$Q('#input-qty').focus()
        return
      }
      if (this.inoutOption === ConstDef.자재출고 && (this.selectedLine === null || this.selectedLine.length === 0)) {
        this.$snotify.error('출고 라인을 선택하여 주세요')
        this.$Q('#select-line').focus()
        return
      }
      // this.isLoading = true
      const param = {
        발생일자: AppLib.getNow(),
        입출고구분: this.inoutOption,
        입고키: this.receiptInfo.입고키,
        품번: this.receiptInfo.품번,
        수량: this.takeQty,
        출고부서: this.selectedLine,
        입고업체: this.receiptInfo.입고업체,
        유효일: this.receiptInfo.유효일,
        장소위치코드: this.receiptInfo.장소위치코드,
        발주번호: this.receiptInfo.발주번호,
        createid: this.userInfo.userName,
        updateid: this.userInfo.userName
      }
      this.isLoading = true
      this.$_sp.runInsertSqlProc('창고수불', null, [param])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('출고처리 되었습니다.', { timeout: 600 })
          this.initInput()
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterPcode (e) {
      let split = null
      split = AppLib.base64DecodeSubstring(this.qrPcodeKey).split('/')
      if (split.length === 1) {
        this.$snotify.error('일련번호를 확인할 수 없습니다.')
        return
      }
      this.qrKey = parseInt(split[1])
      this.qrPcodeKey = split[0]
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptBypCodeAndKeyAndInput', { 품번: this.qrPcodeKey, 입고키: this.qrKey, 입출고구분: this.$_.findWhere(ConstDef.PURCHASE_INOUT_CODE, { 코드명: '자재입고' }).코드 })
        .then((data) => {
          const receiptInfo = this.$_sp.MakeModel(data)
          if (receiptInfo.length !== 1) {
            this.$snotify.error('해당 품목의 입고 정보가 잘못 되었습니다.')
            this.initInput()
          } else {
            this.receiptInfo = receiptInfo[0]
            if (this.receiptInfo.현재수량 === 0) {
              this.$snotify.error('해당 입고키의 품목은 모두 출고 되었습니다.')
              this.initInput()
            } else {
              this.takeQty = this.receiptInfo.현재수량
              this.showQrScaner = 'none'
              setTimeout(() => {
                this.$Q('#input-qty').focus()
              }, 10)
            }
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      this.selectedPqrPcodeKeyCode = result
      this.onEnterPcode(null)
    },
    refreshLineInfo () {
      this.isLoading = true
      this.$_sp.runNoEncodeFindProc('spFindAllLineName', {})
        .then((data) => {
          this.isLoading = false
          const temp = this.$_sp.MakeModel(data)
          this.lineLookup = []
          for (let i = 0; i < temp.length; i++) {
            this.lineLookup.push(temp[i].라인명)
          }
          this.initInput()
        })
        .catch(error => {
          this.isLoading = false
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">
.temp{
  color:#206094;
}
</style>
