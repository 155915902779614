<template>
  <div>
    <DxTabs
      :data-source="tabs"
      :selected-index.sync="selectedIndex"
      :focus-state-enabled="false"
    />
    <StorageTakeout v-if="selectedIndex === 0" />
    <WeekMrp v-if="selectedIndex === 1" />
  </div>
</template>

<script>
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import DxTabs from 'devextreme-vue/tabs'
import StorageTakeout from './StorageTakeout'
import WeekMrp from './WeekMrp'

export default {
  name: 'BomTab',
  components: {
    DxTabs,
    StorageTakeout,
    WeekMrp
  },
  data: function () {
    return {
      selectedIndex: 0,
      tabs: [
        {
          id: 0,
          icon: 'export',
          text: '자재출고'
        },
        {
          id: 1,
          icon: 'info',
          text: '주간자재소요'
        }
      ]
    }
  },
  created () {
    document.title = ConstDef.TAB_TITLE
    const user = this.$store.getters.getUserInfo
    console.log(user)
    if (user === undefined || user === null) {
      this.$router.push('/')
    }
  },
  mounted () {

  },
  updated () {
  },
  beforeDestroy: function () {

  },
  methods: {

  }
}
</script>

<style lang="scss">
  .notactive {
    display: none
  }
  .dx-tab-text{
    .dx-icon {
      margin-top: -8px!important;
    }
  }
</style>
